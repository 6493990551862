import React from "react";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FithSection from "./FithSection";
import FinalSection from "./FinalSection";

const IndexRouteMeditation = () => {
  return (
    <>
      <ThirdSection />
      <FourthSection />
      <FithSection />
      <FinalSection />
    </>
  );
};

export default IndexRouteMeditation;
