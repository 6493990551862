import React from "react";
import { Grid, Button, Box, Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  img: {
    maxWidth: 200,
    marginBottom: theme.spacing(4),
  },

  title: {
    color: "#4b9fbc",
  },

  btn: {
    color: "white",
    backgroundColor: "#4b9fbc",
  },

  p: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "30px",
    textAlign: "left",
    maxWidth: 500,
  },
}));

const Massages = () => {
  const classes = useStyles();

  const massagesList = [
    {
      name: "Massage assis",
      imgUrl: "/assets/images/massage-assis.png",
      text: "Profitez de 15 minutes d'un massage assis sur chaise érgonomique, le massage le plus adapté en entreprise.",
    },

    {
      name: "Massage des 9 sens",
      imgUrl: "/assets/images/massage-9sens.png",
      text: "Profitez d'une heure d'un massage de 9 sens, un ressourcement garanti qui recentre votre énergie.",
    },
  ];

  return (
    <section className="section">
      <Box mb={4} textAlign="center">
        <Container maxWidth="sm">
          <Typography
            variant="h4"
            component="span"
            color="primary"
            className="text-fourth"
          >
            IKDO®
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            paragraph={true}
          >
            prestations de massages
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="lg" className="container-formations">
        <Box py={5} textAlign="center">
          <Grid container spacing={6}>
            {massagesList.map((item, ind) => (
              <Grid key={ind} item xs={12} md={6}>
                <Box p={3} pb={4}>
                  <img src={item.imgUrl} alt="" className={classes.img} />

                  <Typography
                    variant="subtitle1"
                    component="h6"
                    gutterBottom={true}
                    className={classes.title}
                  >
                    {item.name}
                  </Typography>

                  <p className={classes.p}>{item.text}</p>

                  <Box mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      <NavLink to="/formations">En savoir plus</NavLink>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default Massages;
