import React from "react";
import { Grid } from "@material-ui/core";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  h1: {
    fontFamily: "LEMON MILK",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    marginBottom: "20px",
    alignItems: "center",
    textAlign: "justify",
  },

  h2: {
    fontFamily: "LEMON MILK Light",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22px",
    alignItems: "center",
    textAlign: "justify",
  },

  benefice: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    alignItems: "center",
    textAlign: "left",
    color: "#182731",
  },

  verticalLine: {
    borderLeft: "3px solid",
    paddingLeft: "20px",
  },

  p: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1em",
    lineHeight: "30px",
    textAlign: "justify",
  },
}));

const ThirdSection = () => {
  const classes = useStyles();

  return (
    <div className="section">
      <div className="container-icon-formation text-center">
        <Grid container>
          <Grid item md={1} xs={12}></Grid>

          <Grid item md={5} xs={12}>
            <div className="pt-8">
              <h1 className={clsx(classes.h1)}> Formation </h1>
              <h2 className={clsx(classes.h2)}> Massage Sur Chaîse Assise </h2>
            </div>
          </Grid>

          <Grid item md={5} xs={12}>
            <div className={classes.verticalLine}>
              <p className={classes.benefice}> Les bénéfices </p>

              <p className={classes.p}>
                Recommandé pour les employés au sein d'une entreprise, ce
                massage pratiqué sur une chaise assise ergonomique, offre une
                détente en profondeur, tout en fournissant une tonicité et
                réveil musculaire, ce massage est particulièrement efficace pour
                la remise en forme, la baisse du stress et le calme intérieur.
                <br></br>
                Pour plus d'informations vous trouverez ci-dessous les modules
                traités lors de chaque session ainsi que le déroulement de la
                formation massage chaise assise.
                <br></br>
                Participation à l'hébergement chez moi à Cormont: 20 à 25 euros
                par nuit et par personne en fonction de la taille du groupe.
                Chacun peut amener sa nourriture, c'est plus simple, possibilité
                de cuisiner ensemble selon ses envies.
              </p>
            </div>
          </Grid>

          <Grid item md={1} xs={12}></Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ThirdSection;
