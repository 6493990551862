import React from "react";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FithSection from "./FithSection";
import SixSection from "./SixSection";
import FinalSection from "./FinalSection";

const IndexRouteChaiseAssise = () => {
  return (
    <>
      <ThirdSection />
      <FourthSection />
      <FithSection />
      <SixSection />
      <FinalSection />
    </>
  );
};

export default IndexRouteChaiseAssise;
