import React from "react";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";
import FithSection from "./FithSection";
import SixSection from "./SixSection";
import FinalSection from "./FinalSection";
import Benefices from "./Benefices";

const IndexRouteMassage9sens = () => {
  return (
    <>
      <ThirdSection />
      <FourthSection />
      <Benefices />
      <FithSection />
      <SixSection />
      <FinalSection />
    </>
  );
};

export default IndexRouteMassage9sens;
